.data-view-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.aggregated-total{
    font-weight: 700;
}

.footer-total{
    font-size: 1.4rem;
    color: black;
}

.data-view-container>* table.MuiTable-root {
    /* border-style: solid !important;
    border-bottom: 2px;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px; */
    border-collapse: collapse !important;
    /* border-bottom: 1px; */
    /* border-color: rgb(161, 161, 161); */

}

.data-view-container > * tbody.MuiTableBody-root{
    border-style: solid !important;
    border-bottom: 2px;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
    border-collapse: collapse !important;
    /* border-bottom: 1px; */
    border-color: rgb(161, 161, 161);

}


/* defines color of table header when grouped by certain column */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-colorInfo.MuiAlert-standardInfo.MuiAlert-standard{
    background-color: white;
}

/* style the Grouped By text in column header */
div.MuiBox-root  span {
    font-size: 16px;
    color: black;
    /* margin-right: 6px; */
}

/* style chip */
div.MuiBox-root .MuiButtonBase-root.MuiChip-root{
    background-color: var(--philico-blue);
    color: white;
    margin-left: 6px;
}

div.MuiBox-root .MuiButtonBase-root.MuiChip-root .MuiChip-label{
    color: white;
}

.MuiButtonBase-root.MuiChip-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    color: white;
}

/* remove last empty row of material react table */
div.MuiTableContainer-root ~ div.MuiBox-root {
    display: none;
}

.pending-status{
    background-color: var(--pending-color);
}
.submitted-status{
    background-color: var(--submitted-color);
}
.approved-status{
    background-color: var(--approved-color);
}
.rejected-status{
    background-color: var(--rejected-color);
}
.edited{
    background: var(--edited-color)!important;
}
.resubmitted{
    background: var(--rejected-color)!important;
}

