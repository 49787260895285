 .timesheet-card {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
  }

  .timesheet-card-title {
    color: #004170;
    padding-bottom: 20px;
    text-align: left;
    margin-left: 1px;
    font-size: large;
    font-weight: bold;
  }

  .MuiButton-root:disabled {
    color: white !important;
    background-color: #A49D9C !important;
  }