.reports-wrapper {
    margin-top: 64px;
}

.reports-wrapper.overtime-section{
    margin-bottom: 32px;
}

.accordion-title {
    font-size: 16px;
    font-weight: 600;
    margin-right: 12px;
}

.accordion-reports-wrapper {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    /* height: 300px; */
}

.flex-item-accordion {
    align-self: flex-start;
    flex-basis: 350px;
}

.flexbox-wrapper-total-hours-report{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.select-element-report{
    /* width: 45% */
    flex: 1;
}

.flex-wrap{
    flex-basis: 100%;
    margin-bottom: 16px;
}

.submit-button-wrapper{
    display: flex;
    justify-content: flex-end;
}

.textfield-autocomplete-custom > .MuiInputBase-root.MuiOutlinedInput-root > .MuiChip-root {
    background-color: var(--philico-blue);
    color: white;
    font-family: var(--cv-font);
}