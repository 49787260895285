body {
    font-family: 'Montserrat';
}

.main-body-wrapper-flex{
    display: flex;
    justify-content: space-between;
}

.history-button-group{
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 120px;
    width:100px;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    height: fit-content;
}

.history-button{
    font-family: 'Montserrat' !important;
}

.active-history-button.MuiButton-root {
    background-color: var(--philico-blue) !important;
}

.inactive-history-button.MuiButton-root{
    background-color: var(--philico-blue-transparent) !important;

}

.title-text-history{
    font-size: large;
    font-weight: bold;
    color: var(--philico-blue)
}

.calendar-container{
    display: flex;
    max-width: fit-content;
}

.leave-request-form-grid-wrapper{
    display: grid;
    grid-template-columns: 35% 65%;
    column-gap: 12px;
    grid-template-areas:
    "title title" 
    "type activity"
    "duration comment"
    "location comment"
    "submit submit";
    min-width: 180px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 8px;
    /* flex-wrap: wrap; */
}

.grid-item-title{
    grid-area: title;
    margin-bottom: 12px;
}

.grid-item-type{
    grid-area: type;
}

.grid-item-location{
    grid-area: location;
}

.grid-item-comment{
    grid-area: comment;
    height: calc(100%-12px);
}

.grid-item-submit{
    grid-area: submit;
    width: 100%;
}

.flex-wrapper-input-fields{
    display: flex;

}

.title-flex-item{
    width: 100%;
}

.comment-leave-request.MuiFormControl-root{
    height: 100% !important;
}

.comment-leave-request > .MuiInputBase-root{
    height: 100% !important;
}

.comment-leave-request>.MuiInputBase-root > #outlined-multiline-flexible  {
   height: 100% !important;
}

.react-calendar__tile.react-calendar__month-view__days__day{
    font-family: 'Montserrat' !important;
}

.react-calendar__month-view__weekdays{
    font-family: 'Montserrat' !important;
}

.submit-leave-request.MuiButtonBase-root{
    width: 100% !important;
    margin-top: 16px;
}

.leave-request-data-container{
    margin-top: 64px;
    margin-bottom: 64px;
}

.table-title-leave-requests{
    font-size: 24px;
    font-weight: bold;
    color: var(--philico-blue);
}

.data-view-header{
    display: flex;
    margin-bottom: 16px;
    gap: 32px;
    justify-content: space-between;
}

.approve-reject-button-group{
    display: flex;
    gap: 16px;
    height: fit-content;
}


.legend-color-coding-status{
    align-self: center;
    width: 35px;
    height: 25px;
    border-radius: 8px;
}

.approved-color{
    background-color: rgba(0, 171, 80, 0.645);
}

.rejected-color {
    background-color: rgba(246, 13, 13, 0.795);
}

.submitted-color {
    background-color: rgba(255, 145, 0, 0.795);
}

.not-submitted-color {
    background-color: rgba(77, 77, 77, 0.339);
}

.status-label{
    align-self: center;
    font-size: 16px;
}

.status-chip{
    padding: 8px 12px;
    color: white;
    font-weight: 600;
    font-size: 12px;
    width: 11ch;
    border-radius: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.approve-reject-button-group > .MuiButtonBase-root{
    color: white !important
}