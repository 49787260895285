.calendar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(0, 0, 0);
    /* background-color: #d10000; */
    text-align: center;
    z-index: 1;
    flex-grow: 1;
    font-size: 18px;
    height: 100%;
    width: 343px;
}
.react-calendar__navigation{
    display: none;
}

.react-calendar-date-holder {
    color: #004170;
    text-align: center;
    align-items: center;
    margin-left: 1px;
    font-size: large;
    font-weight: bold;
}

.react-calendar {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border: 0;
    border-radius: 8px;
    width: calc(var(--tile-size)*7);
    --tile-size: 45.6px;
}

.react-calendar__view__mouth-view {
    height: calc(var(--tile-size)*7);
    width: calc(var(--tile-size)*7);
}

.react-calendar__month-view__weekdays {
    font-family: var(--cv-roboto-font);
    align-items: center;
    height: var(--tile-size);
    color: var(--philico-blue);
    text-decoration: none;
    width: calc(var(--tile-size)*7);
}

.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

.react-calendar__month-view__weekdays abbr:not([title^="Mon"]):not([title^="Tue"]):not([title^="Wed"]):not([title^="Thu"]):not([title^="Fri"]) {
    color: #d10000;
}

.react-calendar__month-view__days{
    height: calc(var(--tile-size)*6);
    width: calc(var(--tile-size)*7);
}
.react-calendar__tile {
    box-sizing: border-box;
    border: 2px solid white;
    font-family: var(--cv-roboto-font);
    width: var(--tile-size); /* Set a fixed width */
    height: var(--tile-size); /* Set a fixed height */
    border-radius: 50%;
}

.react-calendar .pending-timesheet{
    box-sizing: border-box;
    border: 2px solid white;
    font-weight: bold;
    color: white;
    background-color: var(--pending-color);
}

.react-calendar .approved-timesheet{
    box-sizing: border-box;
    border: 2px solid white;
    font-weight: bold;
    color: white;
    background-color: var(--approved-color);
}
.react-calendar .rejected-timesheet{
    box-sizing: border-box;
    border: 2px solid white;
    font-weight: bold;
    color: white;
    background-color: var(--rejected-color);
}
.react-calendar .submitted-timesheet{
    box-sizing: border-box;
    border: 2px solid white;
    font-weight: bold;
    color: white;
    background-color: var(--submitted-color);
}
.react-calendar .edited-timesheet{
    box-sizing: border-box;
    border: 2px solid white;
    font-weight: bold;
    color: white;
    background-color: var(--edited-color);
}
.react-calendar .pending-leave{
    box-sizing: border-box;
    border: 2px solid white;
    font-weight: bold;
    color: white;
    background-color: var(--pending-leave-color);
}

.react-calendar__tile--active {
    box-sizing: border-box;
    border: 2px solid white;
    font-weight: bold;
    color: white;
    background: var(--philico-blue);
}
.react-calendar__tile:disabled {
    box-sizing: border-box;
    border: 2px solid white;
    font-weight: bold;
    /* color: white; */
    /* background: var(--philico-blue); */
}
.react-calendar__tile--active {
    background: var(--philico-blue);
    color: white;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    box-sizing: border-box;
    border: 2px solid white;
    font-weight: bold;
    color: white;
    background: var(--philico-blue);
}

.react-calendar__tile--now {
    color:black;
    background: white;
    /* background: var(--philico-blue);  */
    /* font-weight: bold; */
    /* color: #6f48eb;*/
   }

.react-calendar__tile--now:enabled {
    box-sizing: border-box;
    border: 2px solid white;
    /* rgb(0, 254, 17) font-weight: bold; */
}
.react-calendar__month-view__days__day{
    box-sizing: border-box;
    border: 2px solid white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
background: var(--philico-blue);
}

.react-calendar__tile--range:enabled {
    box-sizing: border-box;
    border: 2px solid white;
    background: var(--philico-blue);
    color: white;
}

.react-calendar__tile--rangeStart {
    box-sizing: border-box;
    border: 2px solid white;
    background: var(--philico-blue);
    color: white;
}
.react-calendar__tile--rangeEnd {
    box-sizing: border-box;
    border: 2px solid white;
    background: var(--philico-blue);
    color: white;
}

.icon-button-blue{
    color: var(--philico-blue) !important
}