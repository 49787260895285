.drop-area{
    height: 200px;
    width: 300px;
    border-style: dashed;
    border-color: red;
    border-radius: 16px;
    background-color: rgba(100, 100, 100, 0.1);
}

.container-outer-dragging{
    display: flex;
}


.draggable-item{
    text-align: center;
    height: 100px;
    width: 200px;
    border-style: solid;
    border-color: blue;
    border-radius: 16px;
    background-color: rgba(100, 100, 100, 0.1);
}

.hidden-item{
    display: none;
}