.flexbox-wrapper-timesheet-overview{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin: 32px;
}

.list-text-wrapper{
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 8px 0px;
}

.list-text-title{
    font-size: 22px;
    font-weight: 600;
}

.total-hours{
    font-weight: 800;
}