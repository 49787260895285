.flexbox-wrapper-filter-calendar{
    display: flex;
    flex-direction: column;
}

button.clear-button{
    margin-left: 10px !important;
    margin-bottom: 16px !important;
    padding: 10px 16px !important;
    font-size: 14px;
    color: white;
    background-color: var(--philico-blue);
    border-radius: 8px;
}