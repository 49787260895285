.timesheet-history-card {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    margin-right: 10px;
  }

.timesheet-history-card-title {
    color: #004170;
    padding-bottom: 20px;
    text-align: left;
    margin-left: 1px;
    font-size: large;
    font-weight: bold;
  }

.history-selection {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    /* margin-bottom: 10px; */
    flex-direction: column;
    width: 100%;
}

.infoColumnH2 {
    color: #004170;
    font-size: 20px;
}