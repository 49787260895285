.saved-text .MuiInputBase-input {
  color: green !important;
  transition: color 0.3s ease-in-out;
}

.component-and-saveing-indicator-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
