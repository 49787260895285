/* TimesheetPage.css */

.timemate-block-container {
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - (125px)); */
  width: 100%;
  z-index: 1;
  user-select: none;

}

/* .timemate-upper-block-container {

} */

.timemate-lower-block-container {
  margin-top: 20px;
  /* height: 60%; */
  /* background-color: antiquewhite; */
  /* border: 1px solid black; */
}

.timemate-entry-columns {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2.5fr 2fr;

}

.timemate-history-column {
  display: flex;
  height: 100%;
  /* border: 1px solid black; */
  /* background-color:aqua; */

}

.timemate-calendar-column {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* border: 1px solid black; */
  /* background-color: pink; */

}

.timemate-entry-card-column {
  height: 100%;
  /* border: 1px solid black; */
}

.timemate-blocks-container::-webkit-scrollbar {
  background-color: transparent;
}


.timemate-admin-upper-block {
  display: flex;
  flex-direction: column;
  height: 60%;
  margin-bottom: 64px;
}

.timemate-admin-lower-block {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 40%;
}

.timemate-admin-block-title {
  color: #004170;
  padding-bottom: 20px;
  text-align: left;
  margin-left: 1px;
  font-size: x-large;
  font-weight: bold;
}

.timemate-admin-column-container {
  display: flex;
  /* justify-content: space-between; */
  flex: 1;
  z-index: 1;
  flex-wrap: wrap;
  gap: 16px;
}

.timemate-admin-left-column{
width: 310px;
}

.reviewer-column{
  /* flex: 1 */
}

.timemate-admin-left-column-content{
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  row-gap: 10px;
  max-width: 200px;
  /* overflow-y: auto; */
  /* width: calc(100% - 20px); */
}

.timemate-admin-right-column {
  width: 75%;
}

.timemate-admin-right-column-content-wrapper {
  max-height: 80vh;
  overflow-y: auto;
}

.timemate-admin-right-column-content {
  display: flex;
  flex-wrap: wrap;
  column-gap:10px;
  row-gap: 10px;
  width: calc(100% - 10px);
}

.timemate-admin-column-title {
  color: #004170;
  padding-bottom: 20px;
  text-align: left;
  margin-left: 1px;
  font-size: large;
  font-weight: bold;
}

.header-flexbox-wrapper-timesheets{
  display: flex;
  justify-content: space-between;
}

.timesheet-filter-drop-down{
  min-width: 300px;
  display: flex;
  gap: 16px;
}

.leave-request{
  min-width: 134px;
}

.timesheet-filter-drop-down > .MuiFormControl-root{
width: 100%
}

.flexbox-wrapper-employee-card-approver{
  display: flex;
}

.drag-icon{
  margin-right: 16px;
  cursor: move;
}

