/* define some global css variables */
:root {
  --philico-blue: #004170;
  --philico-blue-transparent: rgb(0, 65, 112, 0.5);
  --bankInfo-flex-basis: 180px;
  --educationCard-flex-basis: 180px;
  --contactDetailsCard-flex-basis: 137px;
  --error-color: #ff3333;

  --padding-top-bottom-dynamic-cv-textfield: 6px; /* change this value if height text field in dynamic cv creation is too high  */
  
  
  /* cv variables START */
  --cvheader-background-color: #004B8D;
  --cv-text-color: #324B77;
  --cv-color-grey: rgb(95, 99, 104);

  /* header variable */
  --cv-header-text-block-font-size: 1.6rem; /*this includes text block nationality, date of birth, ...*/

  /* cv variables END */


  /* cv variables 2nd START */
  --cv-margin-left: 32px;
  --cv-margin-right: 32px;
  --cv-margin-top: 32px;
  --cv-roboto-slap-font: "Roboto Slab", serif;
  --cv-roboto-font: "Roboto", sans-serif;
  --cv-left-col-title-section: 180px;
  --cv-left-col-title-section-final-cv: 145px;

  --cv-font: 'Montserrat';

  --pending-color: #FBBC04;
  --rejected-color: #EA4335;
  --edited-color: #f77a0c;
  --approved-color: #1B7656;
  --submitted-color: #716F69;
  
  --pending-leave-color: #977E35;
  
  /* --white: #ffffff; */
}


html{
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat';
}

h1{
  padding: 0;
  margin: 0
}

p{
  padding: 0;
  margin: 0;
  break-inside: auto;
}

ul{
    margin-block-start: 7px !important;
    margin-block-end: 7px !important;
    padding-inline-start: 20px !important;
    break-inside: auto;
}

ul[class*="MuiList-root"]{
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  padding-inline-start: 0px !important;
}



li{
  break-inside: avoid;
}

/* controls cv that unordered lists are not broken over a page break */
/* ul, ol, li, p {
  break-inside: avoid;
} */
ul[class*="public-DraftStyle"]{
  padding-inline-start: 30px !important;
  margin-block-start: 7px !important;
  margin-block-end: 7px !important;
}

li:not(:last-child) {
  margin-bottom: 7px !important;
}


.single-inline-edit{
  margin-top: 10%;
  border-style: dashed;
  padding: 8px;
}

input {
  background-color: transparent;
  border: 0;
}

input:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}

input:focus{
  border-color: blue;
  background-color: #d3d3d3;
} 


.title-edit-input{
  color: white;
  font-size: 30px;
  /* border-style: dashed; */
  font-weight: bold;
  width: 200px;
  margin-bottom: 10px;
  margin-top: 10px
}

.subtitle-edit-input{
  color: white;
  font-size: 25px;
  /* border-style: dashed; */
  font-weight: bold;
}

.card-wrapper{
  width: 400px;
  display: flex;
  margin: auto;
  margin-top: 30px;
  background-color: grey;
}

h1{
  padding:0;
  margin-bottom: 0;
}

h2{
  padding: 0;
  margin-top: 5px;
}

.inline-editable-card{
  margin-left: 20px;
  color: white;
  margin-right: 20px;
}


.title-card{
  display: flex;
  height: 90px;
  padding: 16px 0px;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  box-shadow: -4px -2px 10px rgba(0, 0, 0, 0.25);
}

.outer-div-content-wrapper{
  display: grid;
  grid-template-columns: 0.6fr 15fr 0.6fr;
  column-gap: 20px;
  max-width: 1700px;
  /* max-width: 1600px; */
  margin: 0 auto;
  height: 100px;
}

.back-button-navigation-grid-item-1{
  justify-self: center;
  align-self: start;
  position: sticky;
  top: 50%;
}

.next-button-navigation-grid-item-3 {
  justify-self: center !important;
  align-self: start;
  position: sticky;
  top: 50%;
}

.flex-box-back-navigation-item-wrapper{
  display: flex;
  flex-direction: column;
}


/* div wrapper where title card and content cards are contained */
.main-content-wrapper{
  /* max-width: min(95%, 1237px); */
  /* margin: auto;  */
  /* max-width: 1237px; */

  /* max-width: 1300px; */
  /* max-width: 1237px; */
  place-self: center stretch !important;
  /* margin: auto; */
  margin-bottom: 100px;
  /* border-style: dashed; */
}

.title-card-illustration-blob{
  display: flex;
}

.illustration-title-card{
  width: 204.4px;
  margin-right: -100px;
  z-index: 2
}

.title-flex-box{
  display: flex;
  flex-direction: column;
  /* border-style: dashed; */
  margin-left: 32px;
  color: var(--philico-blue);
}

/* css style applied to title of title card (Profile Information, Personal Information, ...) */
.title-title-card{
  font-size: 42px;
  font-weight: 500;
}

/* css style applied to title of title card (Enter your profile information, enter your personal information, ...) */
.subtitle-title-card{
  font-size: 22px;
}

/* css style for content card. The content card is the card on each page that contains the input field */
/* Contact Details Card START */
.content-card{
  border-radius: 8px;
  box-shadow: -4px -2px 10px rgba(0, 0, 0, 0.25);
  /* border-style: dashed; */
  /* border-style: dashed;
  border-color: transparent; */
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.content-card-title{
  font-size: 2rem;
  margin-left: 3.2rem;
  /* margin-top: 3.2rem; */
  margin-bottom: 3.2rem;
  font-weight: 600;
  color: var(--philico-blue)
}

.content-card-title.add-a-person{
  margin-top: 3.2rem;
  margin-right: 3.2rem;
}

.content-card-flex-wrapper{
  margin-left: 3.2rem;
  display: flex;
  justify-content: space-between;
  gap: 6%;
  margin-right: 3.2rem;
  flex-wrap: wrap;
}

.flex-item.content-card-1,
.flex-item.content-card-2 {
  /* flex-basis: 47%;
  flex-grow: 1; */
  flex: 1 1 max(var(--contactDetailsCard-flex-basis), 47%);
}

.flex-item.content-card-3,
.flex-item.content-card-4 {
  /* flex-basis: 47%;
  flex-grow: 1;
  margin-top: 1.6rem */
  flex: 1 1 max(var(--contactDetailsCard-flex-basis), 47%);
}

.flex-item.content-card-5 {
  flex: 1 1 max(var(--contactDetailsCard-flex-basis), 47%);
  /* flex-basis: 47%;
  flex-grow: inherit;
  margin-top: 1.6rem; */
  /* margin-bottom: 3.2rem; */
}
/* Contact Details Card END */

/* Address Card START*/
.content-card-flex-wrapper.address-card {
  margin-left: 3.2rem;
  display: flex;
  justify-content: space-between;
  gap: 6%;
  margin-right: 3.2rem;
  /* padding-bottom: 3.2rem; */
}

.flex-item{
  flex-basis: 47%;
  flex-grow: 1;
}

.flex-item.address-card-3, .flex-item.address-card-4, .flex-item.address-card-5, .flex-item.address-card-6{
    margin-top: 1.6rem;
}

.flex-item.address-card-7{
  margin-top: 1.6rem;
 }


.flex-item.address-field{
  flex-grow: 5;
}

.flex-item.locate-me-icon {
  flex-basis: 1%;
  margin-left: -4%
}
/* Address Card END*/

.container-a-element{
  cursor: pointer;
  border-radius: 8px;
}

/* this styles the background when you drag a card */
.react-grid-item.react-grid-placeholder {
  background-color: rgba(0, 65, 112, 0.5);
  color: blue
}

/* Header.jsx START */
.header-flexbox-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* max-width: min(95%, 1237px); */
  margin: auto;
  margin-bottom: 64px;
  /* margin-bottom: -160px; */
  /* position: sticky; */
  /* top: 32px; */
  /* border-style: dashed; */
  /* z-index: 15000; */
  position: relative
  /* background-color: white; */
  /* margin-bottom: 80px; */
    /* margin-bottom: 50px; */
}

.menu-flex-item{
  align-self: flex-start;
}

.MuiSvgIcon-root.hamburger-menu-icon{
  height: 42px;
  width: 42px;
  color: var(--philico-blue)
}

.MuiSvgIcon-root.hamburger-menu-item{
  color: var(--philico-blue);
}

.MuiListItemIcon-root.list-item-icon-ham-menu{
  min-width: 42px;
}

/* css for philico log in header */
.flex-item-header-logo{
  /* align-self: center; */
  margin-bottom: 10px;
  overflow: visible !important;
  width: 50px;
  /* width: fit-content; */
}

/* css for material ui stepper compo in header */
.flex-item-header-bar {
  align-self: center;
  margin-top: 35px;
}

.flex-item-header-page-location{
  align-items: center;
  display: flex;
  align-self: flex-start;
  margin-bottom: 6px;
  position: relative;
  z-index: 2;
  flex: 1;
  font-size: 18px;
  margin-left: 16px;
  margin-top: 18px;
  color: var(--philico-blue)
}

#SpeedDialbasicexample-actions{
  align-self: flex-start !important;
}

.hamburger-icon-speed-dial > .MuiButtonBase-root{
  align-self: flex-start !important;
}

.page-location-string{
  margin-left: 8px;
  margin-top: 2px;
  color: grey
}

.flex-item-header-avatar{
  align-self: flex-start;
  margin-bottom: 6px;
  position: relative;
  z-index: 2;
}

.MuiPopover-root.MuiMenu-root.account-menu-avatar{
  z-index: 5 !important
}

/* .MuiPopover-root.account-menu-avatar {
  z-index: 10 !important
} */

/* Header.jsx END */


.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters{
  font-family: 'Montserrat' !important;
}

.layout{
  cursor: pointer;  /* with this css property, mouse will appear as hand when on card */
}

/* Login Page START */
.login-page-waves{
  position: relative;
}

.login-page-waves::before{
  content: " ";
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  /* background-image: url("./assets/login_page_waves.svg"); */
    background-image: url("./assets/login_page_waves_fig.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: table;
  transform: translate(0, -80px);
  z-index: -1;
}

.login-page-waves-complete {
  /* position: relative; */
    background-image: url("./assets/LoginSvg3.jpg");
    min-width: 100vw;
    min-height: 100vh;
    background-size: cover;
    background-position: left 130px;
    background-repeat: no-repeat;
    z-index: -1;
    display: table;
    position: fixed;
    /* transform: translate(0, 80px); */
    /* overflow: clip; */
}

/* .login-page-waves-complete::before {
  content: " ";
  position: fixed;
  background-size: cover;
  min-width: 100vw;
  min-height: 100vh;
  background-image: url("./assets/LoginSvg2.jpg");
  transform: translate(0, 80px);
  z-index: -1;
} */

.illustration-login-img{
  position: absolute;
  /* margin-top: 3%; */
  margin-top: -100px;
  margin-left: 80px;
  margin-bottom: 5%;
  z-index: 2;
}

.login-page-content-container{
  display: flex;
  /* border-style: dashed;  */
  flex-direction: column;
  width: 30%;
  align-items: center;
  margin: auto;
  /* margin-top: 50%; */
  margin-top: 10%;
}
/* Login Page END */


/* SelectCustomized START */
.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: var(--philico-blue) !important;
}

.MuiInputLabel-root.Mui-focused{
  color: var(--philico-blue) !important;
}

.MuiMenuItem-root.Mui-selected{
  background-color: rgba(25, 118, 210, 0.3) !important;
}

.MuiMenuItem-root.Mui-selected:hover {
  background-color: rgba(25, 118, 210, 0.35) !important;
}

.MuiMenuItem-root:hover{
  background-color: rgba(25, 118, 210, 0.05) !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined{
  font-family: Montserrat;
}
/* SelectCustomized END*/

/* Relationship.js card START */

.marital-status-flexbox-wrapper{
  display: flex;
  gap:6%;
  flex-wrap: wrap;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
}

.marital-status-flex-item-1{
  flex: 1 1 180px;
}

.marital-status-flex-item-2 {
  flex: 1 1 180px;
}

.marital-status-flex-item-3 {
  flex: 1 1 180px;
}

.add-a-person-flexbox-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 6%;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
}

.add-person-flex-item-1{
  flex: 1 1 180px;
  margin-bottom: 1rem;
}

.add-person-flex-item-2 {
  flex: 1 1 180px;
  margin-bottom: 1rem;
}

.add-person-flex-item-3 {
  flex: 1 1 180px;
}

.add-a-person-flexbox-wrapper-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  /* grid-template-areas: "checkboxes button"; */
}

.add-a-person-flex-footer-item-2{
  align-self: flex-end;
}


.grid-marital-status-wrapper{
  display: grid;
  grid-template-columns: 0.95fr 1fr 1fr;
  grid-template-areas: 
    "maritalStatus . ."
    ;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
}


.content-card-flex-wrapper.relationship-card {
  margin-left: 3.2rem;
  display: flex;
  justify-content: space-between;
  gap: 6%;
  margin-right: 3.2rem;
  flex-wrap: wrap;
}

.flex-item.relationship-card-1{
  flex-basis: 47%;
}

.add-a-person-grid-wrapper{
  display: grid;
 
  /* margin-bottom: 3.2rem; */
  grid-template-columns: 1fr 1fr 0.9fr;
  column-gap: 3%;
  grid-template-areas:  
    "fullname birthdate relation"
    "checkboxes checkboxes button"
  ;
  margin-right: 3.2rem;
  margin-left: 3.2rem;
}

.grid-item-4 {
  grid-area: checkboxes;
  align-self: flex-end;
}

.grid-item-5{
  justify-self: flex-end;
  align-self: flex-end;
}
/* Relationship.js card END */



/* Google Login button START */
.google-login-button-logo{
  width: 20px
}
/* Google Login button END */


.MuiTypography-root.MuiFormControlLabel-label{
  line-height: 1;
}



/* Checkbox START */
.MuiCheckbox-root.Mui-checked{
  color: var(--philico-blue) !important
}

.MuiCheckbox-root.Mui-checked.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important
}



/* Checkbox END */

/* Button START */
.MuiButton-root{
  background-color: var(--philico-blue) !important;
  padding: 10px 16px !important;
}

.MuiButton-root.cv-guidelines{
  background-color: white !important;
  color: var(--philico-blue) !important;
  border: 1px solid var(--philico-blue) !important
  
}

.MuiButton-root.cancelButton {
  background-color: #5F5E5E !important;
  padding: 4px 12px !important;
  font-size: 1.1rem !important;
}

.MuiButton-root.deleteButton{
  background-color: var(--error-color) !important;
  padding: 4px 12px !important;
    margin-right: 8px !important;
  font-size: 1.1rem !important;
}

.MuiButton-root.deleteButtonDialog {
  background-color: var(--error-color) !important;
  padding: 6px 12px !important;
  font-weight: 700;
  /* margin-right: 8px !important; */
  font-size: 1.4rem !important;
}

.MuiButtonBase-root.MuiFab-root{
  transform: none !important;
  transition: none !important;
}

/* Button END */


/* LanguageSkillsCard START */

.language-skill-flexbox-wrapper{
  display: flex;
  gap: 6%;
  row-gap: 1.6rem;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  flex-wrap: wrap;
}

.language-skill-flexbox-item-1{
  flex: 1 1 210px;
}

.language-skill-flexbox-item-2 {
  flex: 1 1 210px
}

.language-skill-grid-wrapper {
  display: grid;
  /* to explain repeat auto-fill https: //stackoverflow.com/questions/43662552/getting-columns-to-wrap-in-css-grid */
  grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
  column-gap: 6%;
  row-gap: 1.2rem;

  margin-right: 3.2rem;
  margin-left: 3.2rem;
}


.language-skill-flexbox-wrapper-add-button{
  display: flex;
  margin-right: 3.2rem;
  margin-left: 3.2rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

/* LanguageSkillsCard END */

/* ContractInfoCard.js START */
.contract-info-flexbox-wrapper{
  display: flex;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  flex-wrap: wrap;
  gap: 6%;
}

.contract-info-flex-item-1{
  flex: 1 1 190px;
  margin-bottom: 10px;
}

.contract-info-flex-item-2 {
  flex: 1 1 190px;
    margin-bottom: 10px;
}

.contract-info-flex-item-3 {
  flex: 1 1 190px;
    /* margin-bottom: 10px; */
}


.contract-info-flex-item-4 {
  flex: 1 1 190px;
  /* margin-top: 10px; */
}

.contract-info-flex-item-5 {
  flex: 1 1 190px;
  /* margin-top: 10px; */
}
/* ContractInfoCard.js END */



/* IdentityDocumentCard.jsx START */

.identity-document-radio-button-wrapper{
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  margin-bottom: 0.7rem;
}


.identity-document-flexbox-wrapper{
  display: flex;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  gap: 3%;
  /* row-gap: 10px; */
  row-gap: 0.5rem;
  flex-wrap: wrap;
}

.identity-document-flexbox-item-1{
    flex: 1 1 160px;
}

.identity-document-flexbox-item-2 {
  flex: 1 1 160px;
}
.identity-document-flexbox-item-3 {
  flex: 1 1 160px;
}
/* IdentitiyDocumentCard.jsx END */

/* BankInfoCard.jsx START */

.content-card-subtitle{
  font-size: 1.8rem;
  margin-left: 3.2rem;
  /* margin-top: 3.2rem; */
  margin-bottom: 1.6rem;
  font-weight: 600;
  color: var(--philico-blue)
}

.bank-info-flexbox-wrapper{
  display: flex;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  gap: 6%;
  flex-wrap: wrap;
}

.bank-info-flex-item-1 {
  flex: 1 1 max(var(--bankInfo-flex-basis), 47%);
}

.bank-info-flex-item-2 {
  flex: 1 1 max(var(--bankInfo-flex-basis), 47%);
}

.bank-info-flex-item-3 {
  flex: 1 1 max(var(--bankInfo-flex-basis), 47%);
}

.bank-info-flex-item-4 {
  flex: 1 1 max(var(--bankInfo-flex-basis), 47%);
}

.bank-info-flex-item-5 {
  flex: 1 1 max(var(--bankInfo-flex-basis), 47%);
}

.bank-info-flex-item-6 {
  flex: 1 1 max(var(--bankInfo-flex-basis), 47%);
}

.bank-info-flex-item-7 {
  flex: 1 1 max(var(--bankInfo-flex-basis), 47%);
}

.bank-info-flex-item-8 {
  flex: 1 1 max(var(--bankInfo-flex-basis), 47%);
}

.bank-info-flex-item-9{
  flex: 1 1 100%;
}

/* BankInfoCard.jsx END */

/* LanguageSkillsChildCard.jsx START */
.flex-box-wrapper-child-cards{
  display: flex;
  flex-wrap: wrap;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  margin-top: 5.2rem;
  gap: 3%;
  row-gap: 20px;
}


.language-skills-child-card-wrapper{
  border-top: 1rem solid var(--philico-blue);
  box-shadow: -4px -2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  flex: 1 1 190px;
}

.language-skills-child-card-grid-wrapper{
  display: grid;
  grid-template-columns: 30px 1fr 1fr;
  row-gap: 1.2rem;
  grid-template-areas: 
    "icon title editicons"
    ". language language"
  ;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  /* margin-left: 3.2rem;
  margin-right: 3.2rem */
}

.language-skills-child-card-grid-item-1{
  grid-area: icon;
  align-self: center;
}

.language-skills-child-card-grid-item-2 {
  grid-area: title;
  align-self: center;
}

.language-skills-child-card-title{
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 117%;
  color: #5F5E5E;
}

.language-skills-child-card-grid-item-3 {
  grid-area: editicons;
  align-self: center;
  justify-self: flex-end;
}

.language-skills-child-card-grid-item-4 {
  grid-area: language;
}

.language-skills-child-card-content{
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 117%;
}

.emc-phone-number{
  margin-left: 12px;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 2px;
}

/* LanguageSkillsChildCard.jsx END */

/* RelationshipsChildCard START */

.flex-box-wrapper-relationship-child-cards{
    display: flex;
    flex-wrap: wrap;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    margin-top: 5.2rem;
    gap: 3%;
    row-gap: 20px;
}


.relationships-child-card-wrapper {
  border-top: 1rem solid var(--philico-blue);
  box-shadow: -4px -2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  flex: 1 1 240px;
  /* max-width: 50%; */
}

.relationships-child-card-grid-wrapper {
  display: grid;
  grid-template-columns: 30px 1fr 1fr;
  /* row-gap: 1.2rem; */
  grid-template-areas:
    "icon title editicons"
    ". fullName fullName"
    ". birthdate birthdate"
    ". checks checks"
    /* ". emc emc" */
  ;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.relationships-child-card-grid-item-1 {
  grid-area: icon;
  align-self: center;
}

.relationships-child-card-grid-item-2 {
  grid-area: title;
  align-self: center;
  /* margin-bottom: 1.2rem; */
}

.relationships-child-card-grid-item-3 {
  grid-area: editicons;
  align-self: center;
  justify-self: flex-end;
}

.relationships-child-card-grid-item-4 {
  grid-area: fullName;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* margin-top: 1rem; */
}

.relationships-child-card-grid-item-5{
  grid-area: birthdate;
}

.relationships-child-card-birthdate{
  font-size: 1.4rem;
  font-weight: 500;
}

.relationships-child-card-grid-item-6 {
  grid-area: checks;
  margin-top: 1.6rem;
  align-self: flex-start;

}

.flexbox-wrapper-relationships-child-check-text{
  display: flex;
  gap: 5px;
  row-gap:10px;
  flex-wrap: wrap;
}

.relationships-child-card-flex-item-1{
  flex: 1 1 145px;
  max-width: 250px;
}

.relationships-child-card-flex-item-2{
  flex: 1 1 145px;
  max-width: 250px;
}
/* RelationshipsChildCard END */


/* EducationCard START */
.education-radio-button-wrapper {
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  margin-bottom: 1.6rem;
}


.education-card-flexbox-item-1 {
  flex: 1 1 max(var(--educationCard-flex-basis), 47%);
}

.education-card-flexbox-item-2 {
  flex: 1 1 max(var(--educationCard-flex-basis), 47%);
}

.education-card-flexbox-item-3 {
  flex: 1 1 max(var(--educationCard-flex-basis), 47%);
}

.education-card-flexbox-item-4 {
  flex: 1 1 max(var(--educationCard-flex-basis), 47%);
}

.education-card-flexbox-item-5 {
  flex: 1 1 max(var(--educationCard-flex-basis), 47%);
}
.education-card-flexbox-item-6 {
  flex: 1 1 max(var(--educationCard-flex-basis), 47%);
}

.add-a-degree-flex-footer-item-1{
    flex: 1 1 max(var(--educationCard-flex-basis), 47%);
  /* margin-right: auto; */
}
/* EducationCard END */

/* DegreeChildCard.jsx START */
.degree-child-card-wrapper{
    border-top: 1rem solid var(--philico-blue);
    box-shadow: -4px -2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    flex: 1 1 300px;
}


.degree-child-card-grid-wrapper {
  display: grid;
  grid-template-columns: 30px 1fr 1fr;
  /* row-gap: 1.2rem; */
  grid-template-areas:
    "icon title editicons"
    ". fullName fullName"
    ". birthdate birthdate"
    ". school school"
    ". gradDate gradDate"
    /* ". emc emc" */
  ;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.degree-child-card-grid-item-6 {
  grid-area: school;
  margin-top: 0.4rem;
}

.degree-child-card-grid-item-7 {
  grid-area: gradDate;
  margin-top: 0.4rem;
}

/* DegreeChildCard.jsx END */

/* ExperienceCard.jsx START */
.experience-card-flexbox-wrapper {
  display: flex;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  gap: 3%;
  row-gap: 0.5rem;
  flex-wrap: wrap;
}
/* ExperienceCard.jsx END */


/* ExperienceChildCard.jsx START */
.education-child-card-wrapper {
  border-top: 1rem solid var(--philico-blue);
  box-shadow: -4px -2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  flex: 1 1 400px;
}
/* ExperienceChildCard.jsx END */


/* label of checkboxes and radio buttons */
.MuiFormControlLabel-label{
  font-family: 'Montserrat' !important;
}

/* define color of radio button when checked */
.MuiRadio-root.Mui-checked{
  color: var(--philico-blue) !important
}

/* define space between radio buttons that are row aligned */
.MuiFormGroup-row{
  gap: 5px !important
}


/* Customize Tooltip START */
.MuiTooltip-tooltip{
  background-color: var(--philico-blue) !important;
  font-size: 1.3rem !important;
  font-family: "Montserrat" !important;
}

.MuiTooltip-popper > .MuiTooltip-tooltip.tooltip-skill-index{
  margin-top: -5px !important;
}



.MuiTooltip-arrow{
  color: var(--philico-blue) !important;
}
/* Customize Tooltip END */

/* Customize Date picker START */
.MuiPickersDay-root.Mui-selected{
  background-color: var(--philico-blue) !important;
}

.PrivatePickersYear-root.Mui-selected {
  background-color: var(--philico-blue) !important;
}

.PrivatePickersYear-yearButton.Mui-selected{
  background-color: var(--philico-blue) !important;
}
/* Customize Date picker END */

/* Customize HelperText and ErrorText START */
.MuiFormHelperText-root{
  font-family: "Montserrat" !important;
}
/* Customize HelperText and ErrorText END */

/* Customize CircularProgress START */
.MuiCircularProgress-root{
  color: var(--philico-blue) !important
}

.MuiCircularProgress-root.circular-progress-download-cv {
  color: white !important
}

/* Customize CircularProgress END */

/* Style google places autocomplete dropdown START */
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
  padding: 0;
  margin: 0;
}

/* we move position of icon such that red icon appears */
.pac-item:hover .pac-icon-marker {
  background-position: -18px -161px;
}

/* define background color when we hover over an item */
.pac-item:hover {
  background-color: rgba(0, 65, 112, 1);
}

/* pac-item select styles the case where the user does not hover via mouse but uses key arrows */
.pac-item-selected {
  background-color: rgba(0, 65, 112, 1);
}

.pac-item-selected {
  color: white;
}

.pac-item-selected > .pac-item-query {
  color: white
}

/* define color of text when we hover over an item */
.pac-item:hover {
  color: white
}

/* see: https: //stackoverflow.com/questions/4502633/how-to-affect-other-elements-when-one-element-is-hovered */
.pac-item:hover > .pac-item-query {
  color: white
}
/* Style google places autocomplete dropdown END */

/* Customize MUIRichTextEditor START */
#mui-rte-root:hover{
  border-color: var(--philico-blue) !important;
  background-color: rgba(235, 234, 236, 0.42) !important;
  cursor: text !important
}

/* #mui-rte-container > .MUIRichTextEditor-editorContainer-5{
  padding: 0 !important;
} */

#mui-rte-container> div[class*=MUIRichTextEditor-editorContainer] {
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.4)
}

#mui-rte-container > div[tabindex='0'] {
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.4)
}

#mui-rte-container > #mui-rte-editor{
  padding: 0 !important;
  margin: 0 !important;
}

#mui-rte-container>#mui-rte-editor > #mui-rte-editor-container {
  padding: 0 !important;
  margin: 0 !important;
}

#mui-rte-root{
  border-color: white !important;
}

#mui-rte-toolbar{
  /* display: none !important; */
  visibility: hidden !important;
}

#mui-rte-root:hover  #mui-rte-toolbar{
    display: block !important;
    visibility: visible !important;
}

#mui-rte-root:focus-within #mui-rte-toolbar {
  display: block !important;
  visibility: visible !important;
}


#mui-rte-root:focus-within {
  border-color: var(--philico-blue) !important;
  border-width: 1px !important;
}
/* Customize MUIRichTextEditor END */


/* NextButton.jsx and BackButton.jsx START */
.navigation-button{
  fill: var(--philico-blue) !important;
  align-self: center;
}

.navigation-button-text-style{
  margin-top: 7px;
  font-size: 1.2rem;
  color: var(--philico-blue);
  align-self: center;
}
/* NextButton.jsx and BackButton.jsx END */


/* CV.jsx START */

.a4-page-content-container{
  /* height: 255mm; */
  margin-left: 21mm;
  margin-right: 21mm;
  /* margin-top: 21mm; */
  border-style: dashed;
  border-color: red;
}

/* the cv header contains the philico logo, the cv image, name + position (consultant, senior consultant, ...) */
.cv-header-flexbox-wrapper{
  display: flex;
  flex-direction: row !important;
  /* flex-direction: row; */
  justify-content: space-between;
  height: 35mm;
}

.cv-header-leftcol-flexitem{
  display: flex;
  flex-direction: column;
}

.cv-header-flexbox-wrapper{
  display: flex;
  flex-direction: column;
  /* border-style: dashed; */
}

.cv-header-fullname{
  color: #004170;
  font-weight: bolder;
  font-size: 1.3rem;
  margin-top: 10mm;
}

.cv-header-position{
  color: #004170;
  font-size: 1.2rem;
  font-weight: 600;
}

.cv-header-employee-image{
  max-width:100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.cv-section-title{
  font-weight: 700;
  margin-top: 10mm;
  border-bottom: solid;
  padding-bottom: 1mm;
  font-size: 1.3rem;
  color: #004170;
  /* page-break-after: avoid; */
}

.personal-info-section-grid-wrapper{
  display: grid;
  /* grid-auto-flow: column; */
  grid-row-gap: 4mm;
  margin-left: 5mm;
  margin-top: 5mm;
  grid-template-columns: 35% 55%;
  font-size: 1.3rem;
}

.projection-description-flexbox-wrapper{
  display: flex;
  flex-direction: column;
}

.project-experience-company-location-title{
  color: var(--philico-blue);
  font-weight: 600;
}

.cv-left-col{
  color: #5F5E5E;
}

.project-experience-company-location-subtitle{
  color: #5F5E5E;
  font-weight: 500
}

/* CV.jsx END */


/* ContentCreateCVPage.jsx START */

.content-card-title-cv-preview{
  font-size: 2rem;
  margin-bottom: 3.2rem;
  font-weight: 600;
  color: var(--philico-blue)
}

.title-and-cv-download-button-wrapper{
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

.toggle-button-label{
  font-size: 18px;
  font-weight: 500;
  color: var(--philico-blue)
}

.MuiSwitch-track{
  background-color: var(--philico-blue) !important;
}

.MuiSwitch-switchBase{
  color: var(--philico-blue) !important;
}

.MuiSwitch-switchBase.Mui-checked {
  color: #7c6050 !important;
}

.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
  background-color: #7c6050 !important;
}




.cv-page-wrapper{
  padding-top: 3.2rem;
  width: 210mm;
  height: 297mm;
  box-shadow: -4px -2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.wrapper{
  margin: auto;
}
/* ContentCreateCVPage.jsx END */

/* customize create cv react-pdf-viewer tooltips START */
.rpv-core__tooltip-body{
  background-color: var(--philico-blue) !important;
  color: white !important;

  /* background-color: white !important;
  color: var(--philico-blue) !important; */
  border: 1px solid var(--philico-blue) !important;
  font-size: 1.2rem !important;
  margin-top: 4px !important;
}

.rpv-core__tooltip-body-arrow {
  background-color: var(--philico-blue) !important;
}

.rpv-core__menu-item {
  font-family: "Montserrat" !important;
}

.rpv-core__popover-body{
  border-radius: 8px !important;
}

.rpv-zoom__popover-target{
  font-family: "Montserrat" !important;
  color: white !important
}

.rpv-core__icon{
  stroke: white !important;
    /* stroke: var(--philico-blue) !important; */
}

.rpv-zoom__popover-target-arrow{
  border-color: white rgba(0,0,0,0) rgba(0, 0, 0, 0) !important
}

.rpv-core__spinner {
  /* margin-top: 100px !important; */
  margin-top: -600px !important;
  border-bottom: 2px solid transparent !important;
  border-left: 2px solid transparent !important;
  border-right: 2px solid var(--philico-blue) !important;
  border-top: 2px solid var(--philico-blue) !important;
}
/* customize create cv react-pdf-viewer tooltips END */

/* customize AlertSuccess.jsx START */
.MuiPaper-root.success-alert{
  background-color: var(--philico-blue);
  font-family: "Montserrat";
}

.MuiPaper-root.MuiAutocomplete-paper{
  font-family: var(--cv-font);
}

.MuiPaper-root.error-alert {
  background-color: var(--error-color);
  font-family: "Montserrat";
}

.MuiAlertTitle-root{
  font-family: "Montserrat" !important;
}

.MuiAlert-icon{
  color: white !important
}

.MuiAlert-message{
  color: white !important
}
/* customize AlertSuccess.jsx END */



/* DeletePopover.jsx START */
.grid-wrapper-delete-popover{
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-areas: 
          "icon title"
          ". text"
          ". buttons"
  ;
  padding: 12px;
  max-width: 250px;
}

.grid-item-1-icon{
  grid-area: icon;
}

.grid-item-2-title{
  grid-area: title;
  align-self: center;
  font-size: 1.3rem;
  line-height: 117%;
    /* color: #5F5E5E; */
    font-weight: 600;
}

.grid-item-3-text{
  grid-area: text;
  margin-top: 4px;
  font-size: 1.2rem;
}

.grid-item-4-buttons{
  margin-top: 1.2rem;
  grid-area: buttons;
}
/* DeletePopover.jsx END */
.popover-flexbox-content-wrapper{
  display: flex;
  padding: 4px;
  padding-left: 16px;
  font-size: 1.4rem;
  gap: 4px
}

.popover-text{
  align-self: center
}

.MuiIconButton-root.popover-icon-button{
  color: var(--philico-blue) !important
}


/* UnauthorizedPage.jsx START */
.unauthorized-content-wrapper{
  display: flex;
  width: 80%;
  height: 80%;
  flex-direction: column;
  /* border-style: dashed; */
  margin: auto;
  padding-top: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unauthorized-title{
  font-size: 58px;
  font-weight: 700;
  color: var(--philico-blue);
  text-align: center;
  margin-bottom: 12px;
}

.unauthorized-subtitle{
  font-size: 38px;
  font-weight: 400;
  color: var(--philico-blue);
  text-align: center;
}

.unauthorized-link-text{
  /* text-decoration: underline; */
  border-bottom: 3px solid var(--philico-blue);
  /* padding-bottom:  */
  font-weight: 600;
  cursor: pointer;
}

.unauthorized-image{
  /* border-style: dashed; */
  text-align: center;
  scale: 0.61;
  margin-top: -80px;
}

.session-expired-image {
  /* border-style: dashed; */
  text-align: center;
  scale: 0.65;
  margin-top: -50px;
}

/* UnauthrozedPage.jsx END */

/* Dialog Reset layouts START */
.dialog-reset-layout-wrapper{
  padding: 16px 24px;
}


.dialog-reset-layouts-title{
  font-size: 2rem;
  font-weight: 600;
  color: var(--philico-blue);
  letter-spacing: 0.0075em;
  line-height: 1.6;
}

.dialog-reset-layouts-subtitle{
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.00938em;
  /* color: rgba(0, 0, 0, 0.6); */
  margin-top: 8px;
  margin-bottom: 6px;
}

.MuiButton-root.dialog-reset-layout-button{
  padding: 10px 12px !important;
    margin-right: 8px !important;
    font-size: 1.2rem !important;
    letter-spacing: 0.04em;
}


.MuiButtonBase-root.MuiRadio-root{
  padding: 4px !important;
}

.MuiFormControlLabel-root{
padding: 0px !important
}

/* mui-tel-input START */
.MuiOutlinedInput-root{
  font-family: "Montserrat" !important;
}

.MuiTypography-root{
  font-family: "Montserrat" !important;
}

.MuiIconButton-root.MuiTelInput-IconButton{
  margin-left: -5px !important;
}

.MuiOutlinedInput-input:hover{
  background-color: rgba(235, 234, 236, 0.42) !important;
}

.MuiOutlinedInput-notchedOutline{
  border-color: var(--philico-blue) !important;
}

.MuiOutlinedInput-root.Mui-focused input{
  background-color: rgba(235, 234, 236, 0.42) !important;
}

/* #\:r4\:{
  padding-left: 13px !important;
  margin-left: -9px !important
} */

input[placeholder="79 793 07 63"] {
  padding-left: 13px !important;
  margin-left: -9px !important;
  cursor: text !important
}

/* mui-tel-input END */



/* Speed dial START */
.MuiSpeedDial-fab{
  background-color: transparent !important;
  box-shadow: none !important;
}

.MuiSpeedDialAction-staticTooltipLabel{
  font-family: 'Montserrat' !important;
  min-height: 32px !important;
  white-space: nowrap !important;
  margin-left: 0px !important;
  display: inline-flex !important;
  align-items: center !important;
  color: var(--philico-blue) !important;
  font-weight: 500 !important;
}

.MuiSpeedDialAction-fab{
  border-radius: 8px !important;
}

/* Speed dial END */


/* outlinedinput dynamic cv START */
.outlinedinput-dynamic-cv>.MuiOutlinedInput-input {
  padding: 0px 0px !important
}



.outlinedinput-dynamic-cv>.MuiOutlinedInput-input:hover {
  /* padding: 0px 2px !important */
  border-radius: 4px !important;
}

.outlinedinput-dynamic-cv>.MuiOutlinedInput-notchedOutline {
  border-style: none !important;
}

.outlinedinput-dynamic-cv.Mui-focused>.MuiOutlinedInput-notchedOutline {
  border-style: solid !important;
  border-width: 1px !important;
}

/* multiline styling starts here */
.outlinedinput-dynamic-cv.MuiInputBase-multiline {
  padding: 0px 0px !important;
  height: 100% !important;
}

.outlinedinput-dynamic-cv.MuiInputBase-multiline.certificate {
  padding: 0px 0px !important;
  height: 100% !important;
  width: 100% !important;
}

/* outlinedinput dynamic cv END */




/* Textfield dynamic cv START */

/* decrease padding of input field to decrease height of text */
.textfield-dynamic-cv>.MuiOutlinedInput-root>.MuiOutlinedInput-input {
  /* padding: var(--padding-top-bottom-dynamic-cv-textfield) 14px !important */
  /* padding: var(--padding-top-bottom-dynamic-cv-textfield) 6px !important */
  padding: 0px 0px !important;
}

.textfield-dynamic-cv>.MuiOutlinedInput-root>.MuiOutlinedInput-input:hover {
  /* padding: var(--padding-top-bottom-dynamic-cv-textfield) 14px !important */
  /* padding: var(--padding-top-bottom-dynamic-cv-textfield) 6px !important */
  border-radius: 4px !important;
}

.textfield-dynamic-cv > .MuiOutlinedInput-root{
  padding: 0px !important
}

/* also adjust padding for multiline text field */
.textfield-dynamic-cv>.MuiInputBase-multiline {
  padding: 0px 0px !important
}

/* decrease height of multiline text field by 10 px */
.textfield-dynamic-cv>.MuiInputBase-multiline>.MuiOutlinedInput-input {
  margin-bottom: -10px !important;
}

/* also need to decrease height when hovering, otherwise strange jitter of textfield */
.textfield-dynamic-cv > .MuiInputBase-multiline > .MuiOutlinedInput-input:hover{
  margin-bottom: -10px !important;
}

/* background color of textarea hidden */
.textfield-dynamic-cv>.MuiInputBase-multiline{
  overflow: hidden !important;
}


/* we also need to decrease the top value of the label if we decrease the height of the textfield; otherwise label is not correctly place; computation is directly taken from material ui */
.textfield-dynamic-cv > .MuiInputLabel-root{
  top: calc(var(--padding-top-bottom-dynamic-cv-textfield) - 16.5px) !important;
}

/* if the textfield is filled we do not want to display the label, only if the user focues the field */
.textfield-dynamic-cv > .MuiInputLabel-root.MuiFormLabel-filled{
  color: transparent !important;
  top: calc(var(--padding-top-bottom-dynamic-cv-textfield) - 16.5px) !important;

}

/* color of label set to philico blue if textfield is focused */
.textfield-dynamic-cv>.MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
  color: var(--philico-blue) !important
}

/* no border if textfield is not focused */
.textfield-dynamic-cv > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline{
  border-style: none !important;
}

/* when the field is focused we bring back the border */
.textfield-dynamic-cv > .MuiOutlinedInput-root.Mui-focused >.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-style: solid !important;
}

/* set to zero when focused, otherwise label is too close to content of input field */
.textfield-dynamic-cv > .MuiInputLabel-root.Mui-focused {
  top: 0 !important;
}

.textfield-dynamic-cv>.MuiInputLabel-root.MuiFormLabel-filled {
  top: 0 !important;
}

/* MuiAutocomplete-popper */
.client-type-dropdown-selection.MuiAutocomplete-popper{
  min-width: 250px !important;
}

.client-type-dropdown-selection > .MuiPaper-root{
  font-family: "Montserrat" !important;
}

.client-type-dropdown-selection>.MuiPaper-root > ul {
  padding-inline-start: 0px !important;
}

/* space unordered list elements */
li:not(:last-child) {
  margin-bottom: 10px;
}

li::marker{
  margin-bottom: 30px !important;
  padding-bottom: 30px !important;
}
/* Textfield dynamic cv END */





/* selectfield dynamic cv START */
.selectfield-dynamic-cv.position-philico.MuiInputBase-root{
  font-family: "Montserrat" !important;
  font-size: 1.6rem !important;
  font-weight: 400 !important;
}

.selectfield-dynamic-cv.position-philico-2nd.MuiInputBase-root {
  color: white !important;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}


.selectfield-dynamic-cv > .MuiOutlinedInput-input{
  padding: 2px 0px !important;
  /* padding-right: -2px !important; */
}

/* we also need to decrease the top value of the label if we decrease the height of the textfield; otherwise label is not correctly place; computation is directly taken from material ui */
.selectfield-dynamic-cv.MuiInputLabel-root {
  top: 2px  !important;
}

.selectfield-dynamic-cv.MuiInputLabel-root.Mui-focused {
  top: 0px !important;
}

/* if the textfield is filled we do not want to display the label, only if the user focues the field */
.selectfield-dynamic-cv.MuiInputLabel-root.MuiFormLabel-filled {
  color: transparent !important;
  top: calc(var(--padding-top-bottom-dynamic-cv-textfield) - 16.5px) !important;

}

/* no border if textfield is not focused */
.selectfield-dynamic-cv>.MuiOutlinedInput-notchedOutline {
  border-style: none !important;
}

/* when the field is focused we bring back the border */
.selectfield-dynamic-cv > svg{
  fill: none !important;
  right: 2px !important;
}

.selectfield-dynamic-cv:hover >svg {
  /* fill: var(--philico-blue) !important; */
  fill: white !important;
  right: 2px !important; 
}

.selectfield-dynamic-cv.position-philico:hover>svg {
  /* fill: var(--philico-blue) !important; */
  fill: var(--philico-blue) !important;
  right: 2px !important;
}

/* selectfield dynamic cv END */


/* DynamicCvSummary.jsx START */
.cv-summary-flexbox-wrapper{
  margin-top: 60px;
}

.cv-summary-title{
  font-size: 13px;
  color: var(--philico-blue);
  font-weight: 600;
  border-bottom: solid;
}

.button-add-summary{
  margin-top: 12px
}

.cv-summary-content-flexbox-wrapper{
  display: flex;
  justify-content: space-between;
  /* margin-top: 12px; */
}

.outlinedinput-dynamic-cv.flex-item-1{
  flex-basis: 95%;
  /* align-self: center; */
}

/* DynamicCvSummary.jsx END */


/* ProjectExperience.jsx START */
.flexbox-wrapper-project-experience{
  display: flex;
  /* margin-top: 15px; */
  margin-bottom: 20px;
}


.grid-wrapper-project-experience{
  flex-basis: 95%;
  max-width: 95%;
  display: grid;
  grid-template-columns: 200px;
  grid-template-areas:
  "left-col-date right-col-title"
  ". right-col-subtitle"
  ". right-col-description"  
  ;
}

.grid-wrapper-project-experience-final-cv{
  flex-basis: 95%;
    max-width: 95%;
    display: grid;
    grid-template-columns: 150px;
    grid-template-areas:
      "left-col-date right-col-title"
      ". right-col-subtitle"
      ". right-col-description"
    ;
}

.grid-item-date-col{
  grid-area: left-col-date;
  align-self: start;
}

.flexbox-date-col-wrapper{
  display: flex;
}

.experience-title{
  grid-area: right-col-title;
    align-self: center;
    font-size: 1.3rem;
}

.experience-subtitle {
  grid-area: right-col-subtitle;
  align-self: center;
  font-size: 1.3rem;
  /* border-style: solid;
  border-color: red; */
  /* max-width: 100%; */
  overflow: hidden;
  /* max-width: 100%; */
}

.experience-description {
  grid-area: right-col-description;
  align-self: center;
  font-size: 1.3rem;
}

/* ProjectExperience.jsx END */

/* DynamicCv.jsx START */
.a4-page-dynamic-cv{
  /* max-width: 350mm; */
  min-width: 210mm;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  margin: auto;
  margin-top: 32px;
  padding-bottom: 32px;
  border-radius: 8px;
  border-bottom: 30px solid var(--cvheader-background-color);
}

.cv-guideline-wrappers{
  /* max-width: 350mm; */
  min-width: 210mm;
  margin: auto;
  margin-top: 32px;
 
}

.doc-content{
  padding: 32pt 32pt !important
}


.iframe-cv-guidelines{
  width: calc(451.4pt + 2*72pt); /* this calc comes from google iframe: They have a max width set of 451.4 pt plus padding of 72pt */
  margin: auto;
  height: 90%;
  display: block;
  border-width: 0px;
}

.release-notes{
  margin-top: -64px;
  /* height: 9000px; */
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25); */
  /* border-radius: 8px; */
  /* height: 10000px; */
  /* */
  height: 8000px;
  /* height: 140vh; */
  /* max-height: 1500px;  */
  position: relative;
}

.a4-page-content-container-dynamic-cv{
  padding-top: var(--cv-margin-top);
  margin-left: var(--cv-margin-left);
  margin-right: var(--cv-margin-right);
}



#text-value-length-measure-field, #Test2 {
  position: absolute;
  visibility: hidden;
  font-size: 1.6rem;
  font-family: "Montserrat";
  height: auto;
  width: auto;
  white-space: nowrap;
  /* Thanks to Herb Caudill comment */
}
/* DynamicCV.jsx END */


/* DynamicCvHeader.jsx START */
.header-blue-background{
  height: 70px;
  /* background-color: var(--cvheader-background-color); */
  background-color: var(--philico-blue);
  display: flex;
  border-radius: 8px 8px 0px 0px;
  justify-content: flex-end;
}

.cv-header-flexbox-outer-wrapper{
  display: flex;
}

.cv-header-flexbox-blue-banner{
  /* border-style: solid; */
  width: 100%;
  height: 82px;
  align-self: center;
  background-color: var(--philico-blue);
  margin-left: -65px;
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cv-header-flexbox-name-and-role{
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  justify-content: center;
  z-index: 2;
}

.Philico-logo-cv-header{
  padding: 8px;
}
.Philico-logo-cv-header-2nd {
  justify-self: center;
  margin-right: 16px;
}


img.cv-pic{
  object-fit: cover;
  border-radius: 50%;
  height: 110px;
  width:110px;
  border-style: solid;
  margin-left: 15px;
  margin-top: -40px;
  border-color: #324B77;
  cursor: pointer;
}

.cv-pic-2nd{
    object-fit: cover;
    border-radius: 50%;
    height: 130px;
    width: 130px;
    border-style: solid;
    border-color: #324B77;
    cursor: pointer;
    position: relative;
    z-index: 2;

}

.cv-header-flexbox-wrapper-content{
  display: flex;
  border-bottom: 2px solid var(--cvheader-background-color);
  padding-bottom: 16px;
  margin: 0 32px;
  /* border-style: dashed; */
  /* border-style: dashed;
  border-color: red; */
}

.cv-header-text-content{
  margin-top: 12px;
  margin-left: 36px;
  /* border-style: dashed;
  border-color: red; */
  /* display: flex;
  flex-direction: column; */
  /* border-style: dashed; */
}

.first-name-last-name-flexbox-wrapper{
  display: flex;
  flex-wrap: wrap;
  /* border-style: dashed; */
}

/* .cv-header-employee-name{
  color: var(--cv-text-color);
  font-size: 20px;
  font-weight: 700;
} */


.outlinedinput-dynamic-cv.header-first-name.MuiOutlinedInput-root {
  color: var(--cv-text-color) !important;
  font-size: 2rem !important;
  font-weight: 700 !important;
}

.outlinedinput-dynamic-cv.header-first-name-2nd.MuiOutlinedInput-root {
  color: white !important;
  font-size: 2.4rem !important;
  font-weight: 700 !important;
  font-family: var(--cv-roboto-slap-font) !important;
}

.outlinedinput-dynamic-cv.header-text.MuiOutlinedInput-root {
  color: var(--cv-color-grey) !important;
  font-size: var(--cv-header-text-block-font-size) !important;
  /* font-size: 1.6rem !important */
  /* font-weight: 700 !important; */
}

.cv-header-position{
    font-size: 16px;
  font-weight: 500;
  margin-top: 2px;
}

.cv-header-text-grid-wrapper{
  font-size: var(--cv-header-text-block-font-size);
  row-gap: 4px;
  color: var(--cv-color-grey);
  margin-top: 25px;
  margin-left: 36px;
  display: grid;
  grid-template-columns: 150px max-content;
  grid-template-areas: 
  "nationality-left-col nationality-right-col"
  "birthdate-left-col birthdate-right-col"
  "contact-left-col contact-right-col1"
  ". contact-right-col2"
  ". contact-right-col3"
  ". contact-right-col4"
  ;
}

.cv-header-text-grid-wrapper-final-cv {
  font-size: var(--cv-header-text-block-font-size);
  row-gap: 4px;
  color: var(--cv-color-grey);
  margin-top: 25px;
  margin-left: 36px;
  display: grid;
  grid-template-columns: 150px;
  grid-template-areas:
    "nationality-left-col nationality-right-col"
    "birthdate-left-col birthdate-right-col"
    "contact-left-col contact-right-col1"
    ". contact-right-col2"
    ". contact-right-col3"
    ". contact-right-col4"
  ;
}

.left-col-nationality-2nd {
  grid-area: nationality-left-col;
  justify-self: end;
}

.left-col-nationality{
  grid-area: nationality-left-col;
}

.right-col-nationality{
  grid-area: nationality-right-col;
}

.right-col-linkedin {
  grid-area: linkedin-right-col;
}

.left-col-birth-2nd {
  grid-area: birthdate-left-col;
  justify-self: end;
}

.linkedin-left-col{
  grid-area: linkedin-left-col;
  justify-self: end;
}

.left-col-birth{
  grid-area: birthdate-left-col;
}

.right-col-birth {
  grid-area: birthdate-right-col;
}

.left-col-contact-2nd {
  grid-area: contact-left-col;
  justify-self: end;
}

.left-col-contact{
  grid-area: contact-left-col;
  margin-top: 16px;
}

.right-col-contact {
  grid-area: contact-right-col1;
   margin-bottom: 2px;
}

.right-col2{
  grid-area: contact-right-col2;
}

.right-col3 {
  grid-area: contact-right-col3;
}

.right-col4 {
  grid-area: contact-right-col4;
}

 #first-name-measure {
   position: absolute;
   visibility: hidden;
   /* border-style: dashed; */
   font-size: 2.4rem;
   font-weight: 700;
   letter-spacing: 0.00938em;
   font-family: "Montserrat";
   height: auto;
   width: auto;
   white-space: nowrap;
   /* Thanks to Herb Caudill comment */
 }

#last-name-measure{
  position: absolute;
  visibility: hidden;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: 0.00938em;
  font-family: "Montserrat";
  height: auto;
  width: auto;
  white-space: nowrap;
  /* Thanks to Herb Caudill comment */
}

#position-measure{
  position: absolute;
  visibility: hidden;
  font-size: 2.2rem;
  font-weight: 500;
  letter-spacing: 0.00938em;
  font-family: "Montserrat";
  height: auto;
  width: auto;
  white-space: nowrap;}


  #nationality-measure, #birthday-measure, #phone-measure, #email-measure, #linkedinUrl-measure,
  #street-measure, #street-nr-measure, #zip-measure, #city-measure, #country-measure,
  #complete-address-measure
  {
    position: absolute;
    visibility: hidden;
    font-size: var(--cv-header-text-block-font-size);
    letter-spacing: 0.00938em;
    font-weight: 400;
    font-family: "Montserrat";
    height: auto;
    width: auto;
    white-space: nowrap;
  }

  #image-measure{
        position: absolute;
    visibility: hidden;
    height: auto;
      width: auto;
      white-space: nowrap;
  }

/* DynamicCvHeader.jsx END */

/*  PersonalInfo.jsx of dynamic cv START */


/* PersonalInfo.jsx of dynamic cv END */
.dynamic-cv-personal-info-grid-wrapper{
  font-size: var(--cv-header-text-block-font-size);
    row-gap: 4px;
    column-gap: 12px;
    color: var(--cv-color-grey);
    margin-top: 25px;
    margin-left: 36px;
    display: grid;
    grid-template-columns: 1fr minmax(265px, 1fr);
    grid-template-areas:
      "nationality-left-col nationality-right-col"
      "birthdate-left-col birthdate-right-col"
      "linkedin-left-col linkedin-right-col"
      "contact-left-col contact-right-col1"
      ". contact-right-col2"
      ". contact-right-col3"
      ". contact-right-col4"
      ". contact-right-col5"
      ". contact-right-col6"
    ;

}

.right-col5{
  grid-area: contact-right-col5;
}

.right-col6 {
  grid-area: contact-right-col6;
}

/* style mui-tel-input dynamic cv START */

.textfield-dynamic-cv.mobile-phone-input > .MuiOutlinedInput-root{
  padding-left: 4px !important;
  font-size: var(--cv-header-text-block-font-size) !important;
}

.textfield-dynamic-cv > .MuiOutlinedInput-root > .MuiAutocomplete-endAdornment {
  right: 0px !important;
}


.textfield-dynamic-cv.mobile-phone-input>.MuiOutlinedInput-root>.MuiInputAdornment-root > button{
  padding: 0px 4px !important;
  /* padding-right: 4px !important; */
}

.textfield-dynamic-cv.mobile-phone-input>.MuiOutlinedInput-root>.MuiInputAdornment-root>p {
    font-size: var(--cv-header-text-block-font-size) !important;
  /* padding-right: 4px !important; */
}

.textfield-dynamic-cv.mobile-phone-input>.MuiOutlinedInput-root>.MuiInputAdornment-root>button >span{
  scale: 0.8 !important
}


.textfield-dynamic-cv.mobile-phone-input>.MuiOutlinedInput-root > input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: var(--cv-color-grey) !important;
}
/* style mui-tel-input dynamic cv END */



/* DynamicCvSummary.jsx START */
.bounding-box-cv-summary-section{
  border-top: 2px solid var(--philico-blue);
  padding: 12px 0px 32px 0px;
  /* margin: 0 32px; */
}

.bounding-box-cv-summary-section-final-cv-2nd {
  border-top: 2px solid var(--philico-blue);
  padding: 12px 0px 12px 0px;
  /* margin: 0 32px; */
}



.bounding-box-cv-summary-section-final-cv {
  border-bottom: 2px solid var(--cvheader-background-color);
  padding: 32px 16px;
  margin: 0 15mm;
}


.cv-summary-section-grid-wrapper{
  display: grid;
  grid-template-columns: var(--cv-left-col-title-section);
  grid-template-areas: 
    "section-title right-col"
  ;
}


.left-col-section-title{
  color: var(--cv-text-color);
  font-family: var(--cv-roboto-slap-font);
  font-size: 2rem;
  font-weight: 600;
  grid-area: section-title;
  /* align-self: center; */
}

.right-col-cv-summary{
  grid-area: right-col;
  font-size: 1.4rem;
  color: var(--cv-text-color);
  margin-top: 2px;
  font-weight: 500;
}
/* DynamicCvSummary.jsx END */


/* DynamicCvSkills.jsx START */
.cv-skills-section-grid-wrapper{
  display: grid;
  grid-template-columns: var(--cv-left-col-title-section);
  grid-template-areas:
    "section-title right-col-key-title"
    ". right-col-key-skills"
    ". right-col-other-title"
    ". right-col-language-skills"
  ;
}

.right-col-key-title{
  color: var(--cv-text-color);
  font-size: 1.8rem;
  font-weight: 600;
  grid-area: right-col-key-title;
  margin-bottom: 4px;
}

.right-col-other-title {
  color: var(--cv-text-color);
  font-size: 1.8rem;
  font-weight: 600;
  grid-area: right-col-other-title;
  margin-top: 32px;
  margin-bottom: 4px;
}

.right-col-key-skills{
  /* margin-top: 8px; */
  grid-area: right-col-key-skills;
}

.right-col-other-skills {
  /* margin-top: 8px; */
  grid-area: right-col-other-skills;
}

.right-col-language-skills{
  color: var(--cv-text-color);
  /* font-family: var(--cv-roboto-slap-font); */
  font-size: 1.8rem;
  font-weight: 600;
    margin-top: 32px;
  grid-area: right-col-language-skills;
}

/* DynamicCvSkills.jsx END */


/* DynamicCvProjectExperience.jsx START */
.cv-project-experience-section-grid-wrapper {
  display: grid;
  grid-template-columns: var(--cv-left-col-title-section);
  grid-template-areas:
    "section-title right-col-key-title"
    ". right-col-key-skills"
    ". right-col-other-title"
    ". right-col-other-skills"
  ;
}

.cv-project-experience-section-grid-wrapper-final-cv {
  display: grid;
  grid-template-columns: 150px;
  grid-template-areas:
    "section-title right-col-key-title"
    ". right-col-key-skills"
    ". right-col-other-title"
    ". right-col-other-skills"
  ;
}

/* DynamicCvProjectExperience.jsx END */


/* Employer.jsx START */
    #employer-measure, #location-measure, #start-year-measure, #end-year-measure {
      position: absolute;
      visibility: hidden;
      font-size: 1.6rem;
      letter-spacing: 0.00938em;
      font-weight: 400;
      font-family: "Montserrat";
      height: auto;
      width: auto;
      white-space: nowrap;
    }
/* Employer.jsx END */


/* DynamicCvDegrees START */
.bounding-box-degree-section {
  /* border-bottom: 2px solid var(--cvheader-background-color); */
  border-top: 2px solid var(--cvheader-background-color);
  padding: 12px 0px 32px 0px;
  /* padding: 32px 16px; */
  /* margin: 0 32px; */
}

.bounding-box-degree-section-final-cv-2nd {
  /* border-bottom: 2px solid var(--cvheader-background-color); */
  /* border-top: 2px solid var(--cvheader-background-color); */
  /* margin-top: 10px; */
  border-top: 2px solid var(--philico-blue);
  padding: 12px 0px 12px 0px;
  /* padding: 32px 16px; */
  /* margin: 0 32px; */
}

.bounding-box-certificate-section {
  /* border-bottom: 2px solid var(--cvheader-background-color); */
  /* border-top: 2px solid var(--cvheader-background-color); */
  padding: 12px 0px 32px 0px;
  /* padding: 32px 16px; */
  /* margin: 0 32px; */
}

.bounding-box-degree-section-final-cv{
  /* border-bottom: 2px solid var(--cvheader-background-color); */
  padding: 32px 16px;
  margin: 0 15mm;
}
/* DynamicCvDegrees END */


/* Degreejsx START */
        #degree-measure,#grad-date-measure,
        #field-of-study-measure, #school-measure, #school-location-measure {
          position: absolute;
          visibility: hidden;
          font-size: 1.6rem;
          letter-spacing: 0.00938em;
          font-weight: 400;
          font-family: "Montserrat";
          height: auto;
          width: auto;
          white-space: nowrap;
        }
/* Degree.jsx END */


/* Certificate.jsx START */
 #certificate-measure, #issuing-org-measure, #end-year-measure{
        position: absolute;
        visibility: hidden;
        font-size: 1.6rem;
        letter-spacing: 0.00938em;
        font-weight: 400;
        font-family: "Montserrat";
        height: auto;
        width: auto;
        white-space: nowrap;
      }
/* Certificate.jsx END */



.MuiButtonBase-root.dynamic-cv{
  font-size: 1.2rem !important;
}

.MuiButtonBase-root.speed-dial-action-button-navigation{
  width: fit-content !important;
  padding: 16px !important;
  text-wrap: nowrap !important;
  color : var(--philico-blue) !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

/* .MuiButtonBase-root.MuiFab-root.MuiFab-circular{
  width: fit-content !important;
} */



/* cv layout css START */
.header-first-name{
  color: var(--cv-text-color) !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.position-philico{
  /* color: var(--cv-text-color) !important; */
  font-size: 1.6rem !important;
  font-weight: 400 !important;
  font-family: "Montserrat";
}

.position-philico-2nd {
  color: white !important;
  font-size: 1.8rem !important;
  font-weight: 500 !important;
}
/* cv layout css END */


.image-div-wrapper{
    /* max-width: 110px; */
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-style: solid;
    border-color: #324B77;
    margin-top: -40px;
    border-radius: 50%;
}

.image-div-wrapper-2nd {
  width: 103px;
  height: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-style: solid;
  border-color: #324B77;
  border-radius: 50%;
}

cv-pic-2nd {
  object-fit: cover;
  border-radius: 50%;
  height: 130px;
  width: 130px;
  border-style: solid;
  border-color: #324B77;
  cursor: pointer;
  position: relative;
  z-index: 1000;

}

.test-cv-pic{
 max-height: 100%;
}

.test-cv-pic-new{
  position: relative;
  height: 100%;
  width: auto;
}

.selected-experience-cv-style{
  font-size: 1.3rem;
  color: var(--cv-text-color);
  font-weight: 400;
}

.bullet-point-experience{
  /* margin-top: "12px" */
}


.other-experience-cv-style{
  font-size: 1.4rem;
    color: var(--cv-text-color);
    font-weight: 400;
}

.date-col-style{
  font-size: 1.2rem;
  color: var(--cv-color-grey);
  font-family: var(--cv-roboto-font);
  margin-top: 2px;
}

.experience-title-style{
  font-size: 1.2rem;
  color: var(--cv-text-color);
  font-weight: 600;
  font-family: var(--cv-roboto-font);
}

.experience-subtitle-style{
  font-size: 1.2rem;
  color: var(--cv-text-color);
  font-weight: 500;
  font-family: var(--cv-roboto-font);
  padding-bottom: 2px;
}

.experience-description-style{
  font-size: 1.2rem;
  color: var(--cv-color-grey);
  font-family: var(--cv-roboto-font);
}

.final-cv-header{
  font-size: 1.3rem;
}

.left-col-final-cv{
  font-size: 1.4rem;
}

.final-cv-summary{
  font-size: 1.3rem;
  font-weight: 500;
}




/* FINAL CV LAYOUT START */
.final-cv-header-name-style{
  color: white;
  font-size: 1.6rem;
  font-family: var(--cv-roboto-slap-font);
  font-weight: 500;
}

.dynamic-cv-personal-info-grid-wrapper-final-layout {
  font-size: var(--cv-header-text-block-font-size);
  row-gap: 4px;
  column-gap: 12px;
  color: var(--cv-color-grey);
  margin-top: 12px;
  margin-left: 36px;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nationality-left-col nationality-right-col"
    "birthdate-left-col birthdate-right-col"
    "contact-left-col contact-right-col1"
    ". contact-right-col2"
    ". contact-right-col3"
    ". contact-right-col4"
    ". contact-right-col5"
    ". contact-right-col6"
  ;

}

.cv-summary-section-grid-wrapper-final-cv {
  display: grid;
  grid-template-columns: var(--cv-left-col-title-section-final-cv);
  grid-template-areas:
    "section-title right-col"
  ;
}

.cv-skills-section-grid-wrapper-final-cv {
  display: grid;
  grid-template-columns: var(--cv-left-col-title-section-final-cv);
  grid-template-areas:
    "section-title right-col-key-title"
    ". right-col-key-skills"
    ". right-col-other-title"
    ". right-col-language-skills"
  ;
}

.skills-and-language-skills-grid-wrapper{
  display: grid;
  width: 100%;
  margin-top: 16px;
  grid-template-columns: var(--cv-left-col-title-section-final-cv) 80mm 61mm;
  grid-template-areas: 
  ". skill-title language-skills-title"
  ". skills-col language-col";
}

.cv-project-experience-section-grid-wrapper-final-cv {
  display: grid;
  grid-template-columns: var(--cv-left-col-title-section-final-cv);
  grid-template-areas:
    "section-title right-col-key-title"
    ". right-col-key-skills"
    ". right-col-other-title"
    ". right-col-other-skills"
  ;
}

.skill-title-left-col{
  color: var(--philico-blue);
  margin-bottom: 4px;
  grid-area: skill-title;
  font-weight: 500;
  margin-right: 16px;
}

.language-title-right-col {
  color: var(--philico-blue);
  margin-bottom: 4px;
  grid-area: language-skills-title;
  font-weight: 500;
  justify-self: end;
}

.final-cv-header-text-left-col{
  font-size: 1rem;
  font-family: var(--cv-roboto-font);
  color: var(--cv-color-grey)
}

.final-cv-header-text-right-col{
  font-size: 1rem;
  font-weight: 500;
  /* color: var(--philico-blue) */
  color: #023862;
}

.left-col-section-titles-final-cv{
  font-size: 1.5rem;
  font-family: var(--cv-roboto-slap-font);
}

.right-col-body-final-cv{
  font-size: 1.2rem;
  font-family: var(--cv-roboto-font);
  font-weight: 400;
  /* color: var(--cv-color-grey) */
  color: var(--philico-blue)
}

.skill-title-style-final-cv{
  font-size: 1.3rem;
  margin-top: 2px;
  font-weight: 600;
  font-family: var(--cv-roboto-slap-font);
}

.selected-experience-cv-style {
  font-size: 1.2rem;
  color: var(--philico-blue);
  font-family: var(--cv-roboto-font);
  font-weight: 400;
}

/* FINAL CV LAYOUT END */


.cv-guideline-button-wrapper{
  display: flex;
  justify-content: flex-start;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}


/* manage user roles card START */
.manage-user-roles-wrapper{
  padding: 16px
}

.manage-user-roles-grid-wrapper{
  display: grid;
  margin-top: 12px;
  grid-column-gap: 16px;
  grid-template-columns: repeat(5, 1fr);
}

.user-role-grid-title{
  grid-area: userRoleTitle;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 8px;
  font-weight: 600;
}

.user-role-title{
  font-size: 1.6rem;
  font-weight: 500;
}

 .manage-user-role-papers > .MuiDialog-container > .MuiDialog-paper {
  /* width: 80% !important; */
  max-width: 90vw !important;
 }


/* manage user roles card END */

/* activate/deactive users card START */
.activate-users-grid-wrapper{
  display: grid;
  grid-template-columns: 1fr 150px 1fr;
  margin-top: 8px;
}

.title-active-user{
  color: var(--philico-blue);
  font-weight: 600;
  font-size: 1.6rem;
}

.activate-user-role-papers{
  max-width: inherit !important;
}

.list-item-activate-user.MuiListItemText-root{
  margin-right: 9px !important
}

.activate-user-role-papers > .MuiDialog-container > .MuiPaper-root{
  max-width: inherit !important;
}

.empty-user-text{
  font-size: 1.4rem;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-style: italic;
  /* margin-top: 12px; */
}

/* activate/deactive users card END */



/* download cv button group START */
.MuiButtonGroup-root.download-cv-button-group{
  position: relative;
  z-index: 2;
}

.MuiButtonBase-root.download-cv-button-individual{
  border-color: white !important;
}

.MuiButtonBase-root.download-cv-button-individual-arrow-down{
  padding: 10px 10px !important;
}

/* download cv button group END */


/* cv 9 dots actions group START */
.cv-actions-dots-group-flexbox-wrapper{
  /* display: flex; */
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-auto-flow: column;
  column-gap: 32px;
  row-gap: 32px;
  margin: 32px 32px;
  /* padding: 12px */
}

.action-group-img{
  height:34px;
}

/* https: //www.youtube.com/watch?v=GGcDLOuKaiA TUTORIAL that explains css */
/* these will limit max number of columns to 3 */
.grid-item-action-group:nth-child(3n+1) {
  grid-column: 1;
}

.grid-item-action-group:nth-child(3n+2) {
  grid-column: 2;
}

.grid-item-action-group:nth-child(3n+3) {
  grid-column: 3;
}

/* cv 9 dots actions group END */

.checkbox-admin-leave-request > .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: var(--philico-blue) !important
}