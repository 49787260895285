
:root{
    --collapse-background-color: #004F88
}


.header-row-skills-matrix.MuiTableRow-root{
    background: var(--philico-blue) !important;
    font-family: var(--cv-font) !important;
}

.header-row-skills-matrix-column-name.MuiTableCell-root{
    color: white !important;
    font-family: var(--cv-font) !important;
    font-size: 1.6rem !important;
    font-weight: 600 !important;
}

.header-row-skills-matrix-column-name.MuiTableCell-root {
    color: white !important;
    font-family: var(--cv-font) !important;
    font-size: 1.6rem !important;
    font-weight: 600 !important;
}

.header-row-skills-matrix > .MuiTableCell-stickyHeader {
    background: var(--philico-blue)
}



.skills-matrix-collapse-title.MuiTableCell-root{
    /* background: var(--collapse-background-color) !important; */
    color: white !important;
    background: var(--philico-blue) !important;
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    font-family: var(--cv-font) !important;
    /* font-style: italic */
}

.collapse-skills-matrix-button.MuiButtonBase-root > svg{
    color: white !important
}

tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
}


.outlined-input-skills-matrix-customized>.MuiOutlinedInput-input {
    padding: 0px 0px !important;
    text-align: left !important;
    font-size: 1.6rem !important;
    line-height: 1.43 !important;
    /* max-width: 50px !important; */
}

.outlined-input-skills-matrix-customized>.MuiOutlinedInput-input:hover {
    /* padding: 0px 2px !important */
    border-radius: 4px !important;
    background-color: var(--input-fields-hover-color);
}

.outlined-input-skills-matrix-customized>.MuiOutlinedInput-notchedOutline {
    border-style: none !important;

}

.outlined-input-skills-matrix-customized.Mui-focused>.MuiOutlinedInput-notchedOutline {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: var(--philico-blue) !important;
}

/* multiline styling starts here */
.outlined-input-skills-matrix-customized.MuiInputBase-multiline {
    padding: 0px 0px !important;
    height: 100% !important;
}

.outlined-input-skills-matrix-customized.MuiInputBase-multiline.certificate {
    padding: 0px 0px !important;
    height: 100% !important;
    width: 100% !important;
}

.input-skills-matrix-days{
    max-width: 75px !important;
}

.input-skills-matrix-pm{
    width: 100% !important;
    font-size: 1.6rem !important;
    /* max-width: 350px !important; */
    /* min-width: 350px !important; */
}

.skill-matrix-table-cell.MuiTableCell-root{
    font-family: var(--cv-font);
    font-size: 1.6rem;
}

/* select field dynamic skills matrix START */
/* selectfield dynamic cv START */
.selectfield-skills-matrix.position-philico.MuiInputBase-root {
    font-family: "Montserrat" !important;
    font-size: 1.6rem !important;
    font-weight: 400 !important;
}

.selectfield-skills-matrix.position-philico-2nd.MuiInputBase-root {
    color: white !important;
    font-size: 1.6rem !important;
    font-weight: 500 !important;
}


.selectfield-skills-matrix>.MuiOutlinedInput-input {
    padding: 2px 0px !important;
    /* padding-right: -2px !important; */
}

/* we also need to decrease the top value of the label if we decrease the height of the textfield; otherwise label is not correctly place; computation is directly taken from material ui */
.selectfield-skills-matrix.MuiInputLabel-root {
    top: 2px !important;
}

.selectfield-skills-matrix.MuiInputLabel-root.Mui-focused {
    top: 0px !important;
}

/* if the textfield is filled we do not want to display the label, only if the user focues the field */
.selectfield-skills-matrix.MuiInputLabel-root.MuiFormLabel-filled {
    color: transparent !important;
    top: calc(var(--padding-top-bottom-dynamic-cv-textfield) - 16.5px) !important;

}

/* no border if textfield is not focused */
.selectfield-skills-matrix>.MuiOutlinedInput-notchedOutline {
    border-style: none !important;
}

/* when the field is focused we bring back the border */
.selectfield-skills-matrix>svg {
    fill: none !important;
    right: 2px !important;
}

.selectfield-skills-matrix:hover>svg {
    /* fill: var(--philico-blue) !important; */
    fill: var(--philico-blue) !important;
    right: 2px !important;
}

.selectfield-skills-matrix.position-philico:hover>svg {
    /* fill: var(--philico-blue) !important; */
    fill: var(--philico-blue) !important;
    right: 2px !important;
}

/* selectfield dynamic cv END */

/* select field dynamic skills matrix END */