.grid-skills-matrix-overview-wrapper{
    display: grid;
    margin-top: 120px;
}

/* .user-name-table-header{
    align-self: center;
    transform: rotate(-90deg);
    word-wrap: nowrap;
    text-wrap: nowrap;
    padding-left: 12px;
    /* background-color: blue; }*/

/* 
.skill-score{
    justify-self: center;
} */


.MuiTableCell-root.skills-matrix-ov-skill-index{
    /* border-radius: 8px; */
}

.rotate div {
    position: absolute;
}

.rotate {
    /* height: 150px; */
transform: rotate(-90deg);
    /* -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg); */
    /* writing-mode: bt-rl; */
    text-indent: -3em;
    text-wrap: nowrap;
    padding: 0px 0px 0px 0px;
    margin: 0px;
    /* text-align: left;
    vertical-align: top; */
}


/* .skills-matrx-ov-name-header{
    text-wrap: nowrap;
    text-align: center !important;
    padding: 0px !important;
}

.skills-matrix-ov-skill-index{
    text-align: center !important;
} */

/* .rotate {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
    -moz-transform: rotate(-90.0deg);
    -ms-transform: rotate(-90.0deg);
    -o-transform: rotate(-90.0deg);
    -webkit-transform: rotate(-90.0deg);
    transform: rotate(-90.0deg);
    position: absolute;

} */